"use strict";

(function () {
  var vicCookie = "vicCookie";
  var vicTactonCookie = "tactonViCookie";
  var vicFamilyCookie = "viFamily";
  var app = angular.module('norgrenApp');
  app.value('$routerRootComponent', 'mainapp');
  app.service('AjaxService', function ($http) {
    return InititaliseService($http);
  });
  app.controller("baseController", function ($scope, $q, $sce) {
    var self = this;
    self.translations = {};
    self.ModelJS = InitModelJS();

    self.getTranslations = function (keys) {
      var deferred = $q.defer();
      keys.forEach(function (key, index) {
        window.getTranslation(key).then(function (data) {
          $scope.$apply(function () {
            if (data === undefined) {
              return;
            }

            self.translations[key] = $sce.trustAsHtml(data) || "PH - " + key;
          });

          if (index === keys.length - 1) {
            //resolve the promise after the final translation is complete
            deferred.resolve();
          }
        });
      });
      return deferred.promise;
    };

    self.isTacton = function (familyName) {
      return familyName === "VR10" || familyName === "VR15";
    };
  });
  app.component('viController', {
    controller: ['$http', '$scope', 'AjaxService', '$attrs', '$rootRouter', '$window', '$q', '$sce', '$controller', 'tactonRootObject', function ($http, $scope, AjaxService, $attrs, $rootRouter, $window, $q, $sce, $controller, tactonRootObject) {
      angular.extend(this, $controller('baseController', {
        $scope: $scope
      })); // If the user is using a Safari browser a cookie has to be set first.

      var cookie = $("#setCookie").html();
      var sessionStatus = window.location.search.substring(13, 15);

      if (cookie === "true" && sessionStatus !== "OK") {
        var vicUrl = $("#vicUrl").html();
        window.location.replace("https://imi-precision-embedded.partcommunity.com/3d-cad-models/iframe?return=" + vicUrl);
      }

      ShowAjaxLoader();
      var self = this; //important to keep scope!
      //now we will start from an empty object, nothing auto populated. 

      self.Families = [];
      self.isVIController = true;
      self.getTranslations(["price and availability", "Extrabase PandA Login for price", "Product Datasheet (PDF)", "Update configuration", "Save for later", "Submit Quote", "vi-quote-message", "Part Number", "Description", "Quantity", "Shipping", "Price", "Save configuration", "Review selection and CAD", "Configuration saved succesfully", "Login to save your configuration", "There was an error saving the configuration, please try again", "Please fill in all fields on the form", "Valve Island", "Control option", "Manifold", "Stations", "Accessories", "Complete", "Control option nav", "Manifold nav", "Stations nav", "Accessories nav", "Complete nav", "Tacton Old Cookie Warning Title", "Tacton Old Cookie Warning Text", "Reset configuration", "Download Technical PDF", "No more valve functions", "Your configuration", "Options", "Conforms to atex", "Software Downloads", "Discard Configuration", "3D Preview", "View Cad", "Required field", "atex confirm title", "atex confirm text VS18", "atex confirm text VS26", "atex confirm", "Setup in Jde", "View BOM", "print", "Close", "View Image", "VM Series", "VS Series", "Product Certificates", "Qualify Certificates", "HSE Certification", "Previous step", "Next step", "Next station", "ManifoldHeader", "ManifoldIntro", "Station", "Please Select", "Select Family", "Please Select", "AtexIntro", "ControlOptionIntro", "Atex tool tip", "atex summary VS18", "atex summary VS26", "Expand all stations", "More Information", "Add To Basket", "update", "VI List Price", "VI Net Price"]).then(function () {
        self.ModelJS.NavigationList = initialiseNavigation(self.translations, false);
      });
      self.stemImage = '';

      self.GetStemImage = function () {
        try {
          if (self.ModelJS.StemAttributesList && self.ModelJS.StemAttributesList.length > 0) {
            // Use TagText as this should be static between environments, whereas AttributeID could be different
            var index = self.ModelJS.StemAttributesList.map(function (item) {
              return item.TagText;
            }).indexOf('Image');
            self.stemImage = self.ModelJS.StemAttributesList[index].List[0].tagText;
          }
        } catch (ex) {
          self.stemImage = '';
        }
      };

      self.atexConfirmed = false; //start!

      AjaxService.GetFamilies().then(function (result) {
        self.Families = result.data;
        self.ModelJS.Families = self.Families;
        self.ModelJS.DisableLastStationButtons = false; //if we are loading a saved configuration we need to repopulate the self.ModelJS!! 

        var savedUserConfigurationId = -1;

        if ($("#savedUserConfigurationId").html().length > 0) {
          savedUserConfigurationId = $("#savedUserConfigurationId").html();

          if (savedUserConfigurationId > 0) {
            $scope.loadSavedConfig(savedUserConfigurationId);
          }
        } //if we are loading a saved configuration from a partId - we need to repopulate the self.ModelJS!! 
        //almost same as for savedUserConfigurationId


        var partNumberId = -1;

        if ($("#partNumberId").html().length > 0) {
          partNumberId = $("#partNumberId").html();

          if (partNumberId > 0) {
            deleteCookie(vicFamilyCookie);
            deleteCookie(vicTactonCookie); //going to the server with the config ID! 

            AjaxService.RetrieveSavedPartNumberConfiguration(partNumberId).then(function (result) {
              //self.Families = result.data;
              //self.ModelJS.Families = self.Families;
              ShowAjaxLoader(); //console.log("partNumberId: " + partNumberId);
              //populate the ModelJS with the retrieved values:

              self.ModelJS.SavedUserConfigurationID = partNumberId;
              self.ModelJS.Families = result.data.Families;
              self.ModelJS.SelectedFamily = self.ModelJS.Families[result.data.SelectedFamilyIndex];
              $scope.$broadcast('event_FamilySet', {
                family: self.ModelJS.SelectedFamily.familyName,
                configCode: result.data.ConfigCode
              });
              self.ModelJS.DisplayAtexApproval = result.data.HasAtex;
              self.ModelJS.DisplayControlOptions = result.data.HasControlOption;
              self.ModelJS.ShowPinOutPdfLink = result.data.ShowPinOutPdfLink;

              if (result.data.HasAtex) {
                self.ModelJS.AtexApprovals = result.data.AtexApprovals;
                self.ModelJS.SelectedAtexApproval = self.ModelJS.AtexApprovals[result.data.SelectedAtexApprovalsIndex];
                self.ModelJS.AtexTagText = result.data.AtexTagText;
              }

              if (result.data.HasControlOption) {
                self.ModelJS.ControlOptions = result.data.ControlOptions;
                self.ModelJS.SelectedControlOptionIndex = result.data.SelectedControlOptionIndex;
                self.ModelJS.SelectedControlOptions = self.ModelJS.ControlOptions[result.data.SelectedControlOptionIndex];
                self.ModelJS.ControlTagText = result.data.ControlTagText;
                self.ModelJS.ControlToolTip = result.data.ControlToolTip;
              }

              self.ModelJS.SelectedAttributesAndValuesList = result.data.SelectedAttributesAndValuesList;
              self.ModelJS.StemID = result.data.StemID;
              self.ModelJS.StemAttributesList = result.data.StemAttributesList;
              self.ModelJS.StationsList = result.data.StationsList;
              self.ModelJS.AttributesConfigCode = result.data.AttributesConfigCode;
              self.ModelJS.StationsConfigCode = result.data.StationsConfigCode;
              self.ModelJS.RepeatStationsText = $("#repeatStations").html();
              BuildNavigation(self.ModelJS, self.translations, self.isTacton(self.ModelJS.SelectedFamily.familyName));
              GetProductAccesories(self.ModelJS, AjaxService); //these were not saved..

              setCookie(vicFamilyCookie, JSON.stringify(self.ModelJS.SelectedFamily), 2);

              if (self.isTacton(self.ModelJS.SelectedFamily.familyName)) {
                $scope.$broadcast('event_StartTactonVi', {
                  productId: "mc6e4583cd4b4cee9a5ae3d8d4646df7",
                  cookieName: "tactonViCookie",
                  family: self.ModelJS.SelectedFamily.familyName
                });
              }

              if (!self.isTacton(self.ModelJS.SelectedFamily.familyName)) {
                if (self.ModelJS.AttributesConfigCode && !self.ModelJS.AttributesConfigCode.includes('+') && !self.ModelJS.StationsConfigCode.includes('+')) {
                  $scope.GetValvePrice();
                } else {
                  HideAjaxLoader();
                }
              }

              self.ModelJS.CurrentNavigationIndex = 5;
              self.ModelJS.CurentNavigationTypeToDisplay = 5;
            });
          }
        } //if we are loading with an initial family - we need to repopulate the self.ModelJS!! 


        var initialFamilyId = -1;

        if ($("#initialFamilyId").html().length > 0) {
          initialFamilyId = $("#initialFamilyId").html();

          if (initialFamilyId > 0) {
            ShowAjaxLoader();
            $scope.familyChanged(initialFamilyId);
          }
        } // The user has navigated directly to this page.


        if ($attrs.configuredbytype === 'False' && partNumberId === "0" && savedUserConfigurationId === "0") {
          var cookieValue = getGwsCookie(vicCookie);

          if (cookieValue) {
            setTimeout(function () {
              $.fancybox.open($("#reset-vi-configurator-dialog"));
            }, 0);
            $scope.loadSavedConfig(cookieValue);
          }

          HideAjaxLoader();
        }

        var viFamilyRaw = getGwsCookie("viFamily");

        if (viFamilyRaw !== '') {
          // Tacton doesn't give us family data, we need to store it in a cookie
          // so we can show the VI image, datasheet, etc
          var viFamilyObject = JSON.parse(viFamilyRaw);

          if (self.isTacton(viFamilyObject.familyName)) {
            $scope.$broadcast('event_FamilySet', {
              family: viFamilyObject.familyName
            });
            $scope.familyChanged(viFamilyObject.familyID);
          }
        }

        if (initialFamilyId === "0") {
          HideAjaxLoader();
        }
      });
      AjaxService.Documents().then(function (result) {
        self.ModelJS.Docs = result.data;
      });

      $scope.loadSavedConfig = function (userConfigurationId) {
        deleteCookie(vicFamilyCookie);
        deleteCookie(vicTactonCookie);
        AjaxService.RetrieveSavedUserConfiguration(userConfigurationId).then(function (result) {
          ShowAjaxLoader();
          self.ModelJS.SavedUserConfigurationID = userConfigurationId;
          self.ModelJS.Families = result.data.Families;
          self.ModelJS.SelectedFamily = self.ModelJS.Families[result.data.SelectedFamilyIndex];
          $scope.$broadcast('event_FamilySet', {
            family: self.ModelJS.SelectedFamily.familyName,
            configCode: result.data.ConfigCode
          });
          self.ModelJS.ShowPinOutPdfLink = result.data.ShowPinOutPdfLink;
          self.ModelJS.DisplayAtexApproval = result.data.HasAtex;
          self.ModelJS.DisplayControlOptions = result.data.HasControlOption;

          if (result.data.HasAtex) {
            self.ModelJS.AtexApprovals = result.data.AtexApprovals;
            self.ModelJS.SelectedAtexApproval = self.ModelJS.AtexApprovals[result.data.SelectedAtexApprovalsIndex];

            if (self.atexConfirmed === false && self.ModelJS.SelectedAtexApproval.attributeValueID === 3) {
              $.fancybox.open($('#atex-confirm'), {
                'hideOnOverlayClick': false,
                width: 450,
                height: 'auto',
                openEffect: 'none',
                closeEffect: 'none',
                centerOnScroll: true,
                'autoSize': false,
                helpers: {
                  overlay: {
                    closeClick: false
                  }
                },
                'closeBtn': false,
                closeClick: false,
                beforeClose: function beforeClose() {
                  return $scope.$ctrl.atexConfirmed;
                }
              });
            }

            self.ModelJS.AtexTagText = result.data.AtexTagText;
          }

          if (result.data.HasControlOption) {
            self.ModelJS.ControlOptions = result.data.ControlOptions;
            self.ModelJS.SelectedControlOptionIndex = result.data.SelectedControlOptionIndex;
            self.ModelJS.SelectedControlOptions = self.ModelJS.ControlOptions[result.data.SelectedControlOptionIndex];
            self.ModelJS.ControlTagText = result.data.ControlTagText;
            self.ModelJS.ControlToolTip = result.data.ControlToolTip;
          }

          self.ModelJS.SelectedAttributesAndValuesList = result.data.SelectedAttributesAndValuesList;
          self.ModelJS.Translations = result.data.Translations;
          self.ModelJS.StemID = result.data.StemID;
          self.ModelJS.StemAttributesList = result.data.StemAttributesList;
          self.ModelJS.StationsList = result.data.StationsList;
          self.ModelJS.AttributesConfigCode = result.data.AttributesConfigCode;
          self.ModelJS.StationsConfigCode = result.data.StationsConfigCode;
          self.ModelJS.RepeatStationsText = $("#repeatStations").html();
          BuildNavigation(self.ModelJS, self.translations, self.isTacton(self.ModelJS.SelectedFamily.familyName));
          GetProductAccesories(self.ModelJS, AjaxService); //these were not saved..

          setCookie(vicFamilyCookie, JSON.stringify(self.ModelJS.SelectedFamily), 2);

          if (self.isTacton(self.ModelJS.SelectedFamily.familyName)) {
            $scope.$broadcast('event_StartTactonVi', {
              productId: "mc6e4583cd4b4cee9a5ae3d8d4646df7",
              cookieName: "tactonViCookie",
              family: self.ModelJS.SelectedFamily.familyName
            });
          }

          var selectedIndex = 0; // find where the user has progressed to in the configuration process.

          for (var i = 0, len = self.ModelJS.NavigationList.length; i < len; i++) {
            if (!self.ModelJS.NavigationList[i].Completed) {
              selectedIndex = i;
              break;
            }
          }

          selectedIndex = GetValidNavigationIndex(selectedIndex, self.ModelJS);
          $scope.$emit('event_NavigationChangeEvent', {
            data: {
              index: selectedIndex
            }
          });

          if (self.ModelJS.AttributesConfigCode && !self.ModelJS.AttributesConfigCode.includes('+')) {
            $scope.GetValvePrice();
          } else {
            HideAjaxLoader();
          }
        });
      };

      $scope.familyChanged = function (familyId) {
        AjaxService.ChangeFamily(familyId).then(function (result) {
          AjaxService.Documents().then(function (result) {
            self.ModelJS.Docs = result.data;
          }); //we recreate the modelJS

          var currentFamilies = self.ModelJS.Families;
          self.ModelJS = InitModelJS();
          self.ModelJS.InitialFamilyId = familyId;
          self.ModelJS.CurentNavigationTypeToDisplay = 2;
          self.ModelJS.Families = currentFamilies;
          self.ModelJS.ShowPinOutPdfLink = result.data.ShowPinOutPdfLink;
          self.ModelJS.DisplayAtexApproval = result.data.HasAtex;
          self.ModelJS.DisplayControlOptions = result.data.HasControlOption; //how to select the value:

          var index = getFamilyIndexForId(self.ModelJS.Families, familyId);

          if (index > -1) {
            self.ModelJS.SelectedFamily = self.ModelJS.Families[index];
            setCookie(vicFamilyCookie, JSON.stringify(self.ModelJS.SelectedFamily), 2);
          } //The URL contains the family name so we have to update the URL when the family changes


          $scope.UpdateURLOnFamilyChange();

          if (result.data.HasAtex) {
            self.ModelJS.AtexTagText = result.data.AtexTagText;
            self.ModelJS.AtexApprovals = result.data.AtexApprovals;
            self.ModelJS.SelectedAtexApproval = result.data.AtexApprovals[0];
          }

          if (result.data.HasControlOption) {
            self.ModelJS.ControlOptions = result.data.ControlOptions;
            self.ModelJS.SelectedControlOptionIndex = -1;
            self.ModelJS.ControlTagText = result.data.ControlTagText;
            self.ModelJS.ControlToolTip = result.data.ControlToolTip;
            self.ModelJS.AttributesConfigCode = result.data.ConfigCode;
            self.ModelJS.StationsConfigCode = "";
          }

          BuildNavigation(self.ModelJS, self.translations, self.isTacton(self.ModelJS.SelectedFamily.familyName));
          GetProductAccesories(self.ModelJS, AjaxService);
          setTimeout(function () {
            $('.match-height').css("height", "");
            window.matchColumnHeights();
          }, 10);
          $scope.$broadcast('event_FamilySet', {
            family: self.ModelJS.SelectedFamily.familyName,
            configCode: self.ModelJS.AttributesConfigCode
          });
          $scope.$broadcast('event_NavigationChangeEvent', {
            data: {
              index: 1
            }
          });

          if (self.isTacton(self.ModelJS.SelectedFamily.familyName)) {
            $scope.$broadcast('event_StartTactonVi', {
              productId: "mc6e4583cd4b4cee9a5ae3d8d4646df7",
              cookieName: "tactonViCookie",
              family: self.ModelJS.SelectedFamily.familyName
            });
          }

          HideAjaxLoader();
        });
      };

      $scope.$on('event_FamilyChanged', function (event, data) {
        //get the familyId = data.data
        if (data.data === undefined) {
          var currentFamilies = self.ModelJS.Families;
          self.ModelJS = InitModelJS();
          self.ModelJS.Families = currentFamilies;
        } else {
          $scope.familyChanged(data.data);
        }
      });

      $scope.UpdateURLOnFamilyChange = function () {
        //look for uk/en/configure/<family name>
        if (window.location.href.indexOf(self.ModelJS.MarketCode.toLowerCase() + '/' + self.ModelJS.LanguageCode.substring(0, 2).toLowerCase() + "/configure/") > -1 && window.location.href.indexOf(self.ModelJS.SelectedFamily["familyName"]) === -1) {
          window.history.pushState("", "", encodeURIComponent("/configure/" + self.ModelJS.SelectedFamily["familyName"].toLowerCase() + "&configuredbytype=true"));
        } //look for uk/en/vic.aspx?family=<family name>


        if (window.location.href.indexOf(self.ModelJS.MarketCode.toLowerCase() + '/' + self.ModelJS.LanguageCode.substring(0, 2).toLowerCase() + "/vic.aspx?family=") > -1 && window.location.href.indexOf(self.ModelJS.SelectedFamily["familyName"]) === -1) {
          window.history.pushState("", "", encodeURIComponent("/vic.aspx?family=" + self.ModelJS.SelectedFamily["familyName"].toLowerCase() + "&configuredbytype=true"));
        }
      };

      $scope.$on('event_AtexChanged', function (event, data) {
        if (data === undefined) {
          //we remove it from the SelectedAttributesAndValuesList 
          RemoveFromSelectedAttributesAndValuesList(self.ModelJS.SelectedAttributesAndValuesList, 16);
        } else {
          // we add it to the SelectedAttributesAndValuesList
          var index = getIndexForId(self.ModelJS.AtexApprovals, data.attributeValueID);

          if (index > -1) {
            self.ModelJS.SelectedAtexApproval = self.ModelJS.AtexApprovals[index];
            self.ModelJS.SelectedAttributesAndValuesList = [];
            self.ModelJS.SelectedAttributesAndValuesList.push(data);
          }
        }

        var sendToServerModelJS = CreateSendToServerModelJS(self.ModelJS);
        AjaxService.ChangeAtexOrControlOption(sendToServerModelJS).then(function (result) {
          var rez = result.data; // returns a subset of ModelJS  { ControlOptions: [..], StemAttributesList: [..], SelectedAttributesAndValuesList: []  }  (class SendToClientModelJS)
          //we always return the new ControlOptions List and SelectedAttributesAndValuesList. 
          //we can also have the list for StemAttributesList (if we have the selected control option)

          self.ModelJS.ControlOptions = result.data.ControlOptions;
          self.ModelJS.ControlToolTip = result.data.ControlToolTip;
          self.ModelJS.SelectedAttributesAndValuesList = result.data.SelectedAttributesAndValuesList;
          self.ModelJS.StemAttributesList = result.data.StemAttributesList;
          self.ModelJS.AttributesConfigCode = result.data.AttributesConfigCode;
          self.ModelJS.StationsConfigCode = ""; //console.log(result.data.SelectedControlOptionIndex);

          if (result.data.SelectedControlOptionIndex != -1) {
            var currentControl = self.ModelJS.ControlOptions[result.data.SelectedControlOptionIndex];
            self.ModelJS.SelectedControlOptions = currentControl;

            if (currentControl.disable) {
              self.ModelJS.SelectedControlOptionIndex = -1;
              self.ModelJS.SelectedControlOptions = {};
            }
          }

          $scope.updateConfigurationOwner();
          HideAjaxLoader();
          GetProductAccesories(self.ModelJS, AjaxService);
          setTimeout(function () {
            $('.match-height').css("height", "");
            window.matchColumnHeights();
          }, 0);
        });
      }); //almost same as atex - maybe move to another function 

      $scope.$on('event_ControlOptionChanged', function (event, data) {
        if (data.attributeValueID === undefined) {
          //we remove all StemAttributes and remove from the selected list if it exists 
          RemoveFromSelectedAttributesAndValuesList(self.ModelJS.SelectedAttributesAndValuesList, 1);
          self.ModelJS.AttributesConfigCode = "";
          self.ModelJS.StationsConfigCode = "";
          self.ModelJS.StemID = 0;
          self.ModelJS.SelectedControlOptions = {};
          self.ModelJS.StemAttributesList = [];
          self.ModelJS.StationsList = [];
          self.ModelJS.ProductAccesoryList = [];
          self.ModelJS.SelectedControlOptionIndex = -1; //self.ModelJS.SelectedAttributesAndValuesList = [];  // to be analyzed -> it will loose the atex value if we have one. 
        } else {
          var index = getIndexForId(self.ModelJS.ControlOptions, data.attributeValueID);

          if (index > -1) {
            self.ModelJS.SelectedControlOptions = self.ModelJS.ControlOptions[index];
            self.ModelJS.SelectedAttributesAndValuesList = AddToSelectedAttributesAndValuesList(self.ModelJS.SelectedAttributesAndValuesList, data);
          }

          var sendToServerModelJS = CreateSendToServerModelJS(self.ModelJS);
          AjaxService.ChangeAtexOrControlOption(sendToServerModelJS).then(function (result) {
            var rez = result.data; // returns a subset of ModelJS  { ControlOptions: [..], StemAttributesList: [..], SelectedAttributesAndValuesList: []  }  (class SendToClientModelJS)

            self.ModelJS.SelectedControlOptionIndex = index;
            self.ModelJS.StemID = result.data.StemID;
            self.ModelJS.SelectedAttributesAndValuesList = result.data.SelectedAttributesAndValuesList;
            self.ModelJS.StemAttributesList = result.data.StemAttributesList;
            self.ModelJS.AttributesConfigCode = result.data.AttributesConfigCode;
            self.ModelJS.StationsConfigCode = ""; //still should be empty

            self.ModelJS.StationsList = result.data.StationsList;
            GetProductAccesories(self.ModelJS, AjaxService);
            self.GetStemImage();
            $scope.updateConfigurationOwner();
            HideAjaxLoader();
            setTimeout(function () {
              $('.match-height').css("height", "");
              window.matchColumnHeights();
            }, 0);
          });
        }
      });
      $scope.$on('event_AttributeChangeEvent', function (event, data) {
        if (data === undefined) {//this is an error - 
        } else {
          if (data.attributeValueID === undefined) {
            RemoveFromSelectedAttributesAndValuesList(self.ModelJS.SelectedAttributesAndValuesList, data.attributeID);
          } else {
            self.ModelJS.SelectedAttributesAndValuesList = AddToSelectedAttributesAndValuesList(self.ModelJS.SelectedAttributesAndValuesList, data);
          }

          var sendToServerModelJS = CreateSendToServerModelJS(self.ModelJS);
          AjaxService.ChangeStemAttributeValue(data.attributeID, sendToServerModelJS).then(function (result) {
            var rez = result.data; // returns a subset of ModelJS  { ControlOptions: [..], StemAttributesList: [..], SelectedAttributesAndValuesList: []  }  (class SendToClientModelJS)

            self.ModelJS.SelectedAttributesAndValuesList = result.data.SelectedAttributesAndValuesList;
            self.ModelJS.StemAttributesList = result.data.StemAttributesList;
            self.ModelJS.StationsList = result.data.StationsList;
            self.ModelJS.RepeatStationsText = $("#repeatStations").html();
            self.ModelJS.AttributesConfigCode = result.data.AttributesConfigCode;
            self.ModelJS.StationsConfigCode = result.data.StationsConfigCode;
            self.ModelJS.Translations = result.data.Translations;
            $scope.updateConfigurationOwner();
            GetProductAccesories(self.ModelJS, AjaxService); //do we need to do a new PandA if the attrbutes have been filled in

            if (!self.ModelJS.AttributesConfigCode.includes('+') && !self.ModelJS.StationsConfigCode.includes('+')) {
              $scope.GetValvePrice();
            }

            HideAjaxLoader();
          });
        }
      });
      $scope.$on('event_StationAttributeChangeEvent', function (event, data) {
        if (data === undefined || data.stationIndex === undefined) {//this is an error
        } else {
          var StationIndex = data.stationIndex;

          if (data.attributeValueID === undefined) {
            RemoveFromSelectedAttributesAndValuesList(self.ModelJS.StationsList[StationIndex].SelectedStationAttributesAndValuesList, data.attributeID);
          } else {
            self.ModelJS.StationsList[StationIndex].SelectedStationAttributesAndValuesList = AddToSelectedAttributesAndValuesList(self.ModelJS.StationsList[StationIndex].SelectedStationAttributesAndValuesList, data);
          } //i may need only the one current station and the selected attrib list!! 


          var sendStationToServerModelJS = CreateSendToServerOneStationModelJS(self.ModelJS, StationIndex);
          sendStationToServerModelJS.ChangedAttributeId = data.attributeID;
          AjaxService.ChangeStationAttributeValue(sendStationToServerModelJS).then(function (result) {
            // Pilot supply has been changed if the attributeID equals 5. 
            if (data.attributeID == 5) {
              setPilotSupplyForSubsequentStations(StationIndex + 1, self.ModelJS, data.attributeValueID, result.data);
            }

            self.ModelJS.StationsList[StationIndex] = result.data; // one Station!!

            self.ModelJS.StationsConfigCode = GetTheConfigCodeFromTheStations(self.ModelJS.StationsList, self.ModelJS.StationsList.length);
            $scope.updateConfigurationOwner();
            GetProductAccesories(self.ModelJS, AjaxService);
            $scope.$emit('event_CheckSolenoidsEvent', {
              data: {
                station: self.ModelJS.StationsList[StationIndex]
              }
            }); //do we need to do a new PandA if the attrbutes have been filled in

            if (!self.ModelJS.AttributesConfigCode.includes('+') && !self.ModelJS.StationsConfigCode.includes('+')) {
              $scope.GetValvePrice();
            } // Do we need to display the Blocked Ports warning?


            if (result.data.Popup != null) {
              var initiatePopup = result.data.Popup.InitiatePopup;
              var popupHtml = result.data.Popup.PopupHtml;

              if (initiatePopup) {
                // Check for ignore cookie
                if (!getGwsCookie("ignoreBlockedPortWarning")) {
                  $.fancybox({
                    content: popupHtml,
                    modal: false,
                    width: 450,
                    height: 'auto',
                    openEffect: 'none',
                    closeEffect: 'none',
                    centerOnScroll: true,
                    'autoSize': false,
                    helpers: {
                      overlay: {
                        locked: false
                      }
                    }
                  });
                }
              }
            }

            HideAjaxLoader();
          });
        }
      });
      $scope.$on('event_StationAttributesCopy', function (event, data) {
        //get the familyId = data.data
        if (data.data === undefined) {//this is an error
        } else {
          self.ModelJS.DisableLastStationButtons = true; // From this index copy the selected values and attributes for the remaining stations.

          var stationIndex = data.data;
          var originalStation = self.ModelJS.StationsList[stationIndex];

          for (var i = stationIndex + 1; i < self.ModelJS.StationsList.length; i++) {
            var currentStation = self.ModelJS.StationsList[i]; // Need the partnumber.

            currentStation.PartNumber = originalStation.PartNumber; // The next station's attributes.

            var SelectedStationAttributesAndValuesList = [];

            for (var j = 0; j < currentStation.StationAttributesList.length; j++) {
              // Get the values from the attribute in originalStation.StationAttributesList.
              var currentStationAttribute = currentStation.StationAttributesList[j]; // The foundIndex will be -1 if the original station does not have a blanking station.

              var foundIndex = getIndexIForStationAttributeId(originalStation.StationAttributesList, currentStationAttribute.AttributeID);

              if (foundIndex > -1) {
                var originalStationAttribute = originalStation.StationAttributesList[foundIndex]; // The values of the attribute.

                currentStationAttribute = originalStationAttribute; //we put the element back in the list. 

                currentStation.StationAttributesList[j] = currentStationAttribute; // The current attribute should have a selected value.

                if (originalStationAttribute.SelectedIndex > -1) {
                  var originalStationValuesList = originalStation.SelectedStationAttributesAndValuesList.find(function (element) {
                    return element.attributeID === originalStationAttribute.AttributeID;
                  }); // Add the attribute.

                  var newSelectedPair = {
                    attributeName: originalStationValuesList.attributeName,
                    attributeValueName: originalStationValuesList.attributeValueName,
                    attributeID: originalStationValuesList.attributeID,
                    attributeValueID: originalStationValuesList.attributeValueID
                  };
                  SelectedStationAttributesAndValuesList.push(newSelectedPair);
                }
              } else {
                // This is an even station. Add the default value None for blanking option.
                var blankingOptionIndex = currentStation.StationAttributesList.length - 1; // Blanking option is always the last attribute.

                var blankingOption = {
                  attributeName: currentStation.StationAttributesList[blankingOptionIndex].TagText,
                  attributeValueName: currentStation.StationAttributesList[blankingOptionIndex].List[0].tagText,
                  attributeID: 24,
                  attributeValueID: 4
                };
                SelectedStationAttributesAndValuesList.push(blankingOption);
                currentStation.StationAttributesList[j].SelectedIndex = 0;
              }
            } //Put the value back


            currentStation.SelectedStationAttributesAndValuesList = SelectedStationAttributesAndValuesList;
            self.ModelJS.StationsList[i] = currentStation;
          } //i need to reextract the code for the copyed Stations


          var sendStationsToServerModelJS = CreateSendToServerMultipleStationsModelJS(self.ModelJS, stationIndex);
          AjaxService.GetConfigurationCodeForStations(sendStationsToServerModelJS).then(function (result) {
            var rez = result.data; // returns a string array with the code for the stations, which i will have to add back tothe new stations

            var newStationsConfigCode = "";
            var listOfCodes = result.data;

            for (var i = 0; i < listOfCodes.length; i++) {
              var currentStation = self.ModelJS.StationsList[i];
              currentStation.ConfigCode = listOfCodes[i];
              self.ModelJS.StationsList[i] = currentStation;
              newStationsConfigCode = newStationsConfigCode + listOfCodes[i];
            }

            self.ModelJS.StationsConfigCode = newStationsConfigCode; // Display the last station.

            $scope.$emit('event_NavigateStations', {
              data: {
                stationId: self.ModelJS.StationsList.length - 1
              }
            });
            $scope.updateConfigurationOwner();
            GetProductAccesories(self.ModelJS, AjaxService); //do we need to do a new PandA if the attrbutes have been filled in

            if (!self.ModelJS.AttributesConfigCode.includes('+') && !self.ModelJS.StationsConfigCode.includes('+')) {
              $scope.GetValvePrice();
            }
          });
        }
      }); //the button for the price and availability in the productAccesory list! 

      $scope.$on('event_ProductAccesoryListEvent', function (event, data) {
        ShowAjaxLoader();
        AjaxService.GetLoggedInAccountId(self.ModelJS.MarketCode, self.ModelJS.LanguageCode).then(function (result) {
          var accountId = parseFloat(result.data);

          if (isNaN(accountId) === false && accountId != 0) {
            AjaxService.GetAccessoriesPandA(self.ModelJS).then(function (result) {
              var rez = result.data; //return the list of ProductAccessories

              self.ModelJS.ProductAccesoryList = rez;
              HideAjaxLoader();
            });
          } else {
            HideAjaxLoader();
            displayDialogBox(self.translations["Extrabase PandA Login for price"]);
          }
        });
      });
      $scope.$on('event_ProductAccesoryAddToBasketEvent', function (event, data) {
        ShowAjaxLoader();
        var sendToServerModelJS = CreateSendToServerModelJSForAccessoriesPandA(self.ModelJS);
        AjaxService.AccessoriesAddToBasket(sendToServerModelJS).then(function (result) {
          var rez = result.data; // Update the basket item count.

          var txt = $('.basket-item-text').html();
          var tokens = txt.split(" ");
          txt = rez + " " + tokens[1];
          UpdateBasketDisplay(txt);
          HideAjaxLoader();
          DisplayAddToBasketDialogVI(window.translation.ProductsWereAddedToTheBasket);
        });
      });
      $scope.$on('event_Previous', function (event, data) {
        var index = self.ModelJS.CurrentNavigationIndex - 1;
        index = GetValidNavigationIndex(index, self.ModelJS);
        $scope.$broadcast('event_NavigationChangeEvent', {
          data: {
            index: index
          }
        });
      });
      $scope.$on('event_Next', function (event, data) {
        var index = self.ModelJS.CurrentNavigationIndex + 1;
        index = GetValidNavigationIndex(index, self.ModelJS);
        $scope.$broadcast('event_NavigationChangeEvent', {
          data: {
            index: index
          }
        });
      });
      $scope.$on('event_NextControlOption', function (event, data) {
        var index = GetValidNavigationIndex(self.ModelJS.CurrentNavigationIndex + 1, self.ModelJS);
        $scope.$emit('event_NavigationChangeEvent', {
          data: {
            index: index
          }
        });
      });
      $scope.$on('event_NextAttributes', function (event, data) {
        var index = self.ModelJS.CurrentNavigationIndex + 1;
        index = GetValidNavigationIndex(index, self.ModelJS);
        $scope.$emit('event_NavigationChangeEvent', {
          data: {
            index: index
          }
        });
      });
      $scope.$on('event_NextStation', function (event, data) {
        if (isStationComplete(self.ModelJS.StationsList[self.ModelJS.CurrentStationIndex])) {
          // If last station, fire the navigation change event.
          if (self.ModelJS.CurrentStationIndex == self.ModelJS.StationsList.length - 1) {
            var index = self.ModelJS.CurrentNavigationIndex + 1;
            index = GetValidNavigationIndex(index, self.ModelJS);
            $scope.$emit('event_NavigationChangeEvent', {
              data: {
                index: index
              }
            });
          } else {
            self.ModelJS.CurrentStationIndex = self.ModelJS.CurrentStationIndex + 1;
            $scope.$emit('event_StationAttributeChangeEvent', {
              data: {
                stationIndex: self.ModelJS.CurrentStationIndex
              }
            });
            $scope.$emit('event_CheckSolenoidsEvent', {
              data: {
                station: self.ModelJS.StationsList[self.ModelJS.CurrentStationIndex]
              }
            });
          }
        }
      });
      $scope.$on('event_NavigateStations', function (event, data) {
        if (data.data !== undefined) {
          var newStationIndex = data.data.stationId;

          if (isStationComplete(newStationIndex == 0 ? self.ModelJS.StationsList[newStationIndex] : self.ModelJS.StationsList[newStationIndex - 1])) {
            self.ModelJS.CurrentStationIndex = newStationIndex;
            $scope.$emit('event_CheckSolenoidsEvent', {
              data: {
                station: self.ModelJS.StationsList[self.ModelJS.CurrentStationIndex]
              }
            });
          } else {
            displayDialogBox(self.translations["Please fill in all fields on the form"]);
          }
        }
      });
      $scope.$on('event_CheckSolenoidsEvent', function (event, data) {
        if (data.data.station !== undefined) {
          var sendStationToServerModelJS = CreateSendToServerOneStationModelJS(self.ModelJS, self.ModelJS.CurrentStationIndex);
          AjaxService.CheckSolenoids(sendStationToServerModelJS, data.data.station).then(function (result) {
            self.ModelJS.StationsList[self.ModelJS.CurrentStationIndex] = result.data;
            var valveFunctions = self.ModelJS.StationsList[self.ModelJS.CurrentStationIndex].StationAttributesList.find(function (element) {
              return element.AttributeID === 6;
            });

            if (valveFunctions.List.length === 0) {
              $scope.$emit('event_NavigateStations', {
                data: {
                  stationId: self.ModelJS.CurrentStationIndex - 1
                }
              });
              $scope.$emit('event_StationAttributeChangeEvent', {
                data: {
                  stationIndex: self.ModelJS.CurrentStationIndex - 1
                }
              });
              displayDialogBox(self.translations["No more valve functions"]);
            }
          });
        }
      });
      $scope.$on('event_NavigationChangeEvent', function (event, data) {
        if (data.data === undefined) {//this is an error
        } else {
          var newIndex = data.data.index;
          self.ModelJS.CurrentNavigationIndex = newIndex;
          self.ModelJS.CurrentStationIndex = 0;
          var navElement = self.ModelJS.NavigationList[newIndex];

          if (navElement === undefined || navElement.Type === undefined) {
            window.location = window.location.href;
            return;
          }

          self.ModelJS.CurentNavigationTypeToDisplay = navElement.Type;

          if (navElement.Type == 4) {
            resizeStationAttributeContainer(self.ModelJS);
          }

          $scope.$broadcast('event_NavigationSet', {
            data: self.ModelJS.CurrentNavigationIndex
          });
          setTimeout(function () {
            $('.match-height').css("height", "");
            window.matchColumnHeights();
            $('a.fancybox').fancybox({
              maxWidth: 840,
              openEffect: 'none',
              closeEffect: 'none',
              centerOnScroll: true,
              autoSize: true
            });
          }, 0);
        }
      });
      $scope.$on('event_AddToBasket', function () {
        ShowAjaxLoader();
        var sendToServerModelJS = CreateSendToServerModelJS(self.ModelJS); //TO DO vic  = get the account id with ajax 

        var baskId = $('#viBasketId').html();
        var id = $('#accountId').html();
        var configJs = CreateSendToServerUserConfigurationModelJS(id, self.ModelJS);
        AjaxService.AddToBasket(baskId, self.ModelJS.PartNumberCode, sendToServerModelJS, configJs).then(function (result) {
          HideAjaxLoader();
          var rez = result.data;
          UpdateBasketDisplay(rez.BasketText);

          if ($("#dialog-message-vi").length) {
            $("#dialog-message-vi").remove();
          }

          $('body').append($('<div />').attr('id', 'dialog-message-vi').attr('title', window.translation.AddToBasket));
          $("#dialog-message-vi").text(rez.Response).hide();
          var buttondialog = window.bskdialogbtns;
          DisplayDialog("#dialog-message-vi", {
            buttons: buttondialog
          });
          DisplayContent(rez.UmbracoContent);
        });
      });
      $scope.$on('event_Price', function (event, model) {
        angular.extend(self.ModelJS, model);
        $scope.GetValvePrice();
      });
      $scope.$on('update_longcode', function (event, longcode) {
        self.ModelJS.AttributesConfigCode = longcode;
        self.ModelJS.StationsConfigCode = '';
      });
      $scope.$on('event_IsValidPartRetrieval', function (event, longcode) {
        var partNumberId = parseInt($("#partNumberId").text());
        AjaxService.IsValidPartRetrieval(longcode, partNumberId).then(function (result) {
          if (!result.data) {
            // Tacton has returned a valve that the user has not asked for, reload the page to try again
            window.location = window.location.href;
            return;
          }
        });
      });

      $scope.GetValvePrice = function () {
        ShowAjaxLoader();
        var id = $scope.getAccountId();
        var configJs = CreateSendToServerUserConfigurationModelJS(id, self.ModelJS);
        var sendToServerModelJS = CreateSendToServerModelJS(self.ModelJS);
        AjaxService.ConvertToProducts(sendToServerModelJS).then(function (result) {
          if (result.data.length > 0) {
            sendToServerModelJS.Products = result.data;
            self.ModelJS.Products = result.data;
          }

          AjaxService.GetValvePandA(sendToServerModelJS, configJs).then(function (result) {
            var rez = result.data;
            self.ModelJS.ShowRequestAQuote = rez.ShowRequestAQuote;
            self.ModelJS.Price = id > 0 || self.ModelJS.AllowAnonPrice === 'True' ? rez.Price : null;
            self.ModelJS.ValveDescription = rez.ValveDescription;
            self.ModelJS.Shipping = rez.Shipping;
            self.ModelJS.PartNumberCode = rez.PartNumberCode;
            $scope.$broadcast('event_productCodeSet', rez.PartNumberCode);
            self.ModelJS.ShowSetupButton = self.ModelJS.IsAdmin === true && rez.ShowSetupInJdeButton && !rez.IsSetupInJde;
            $("#request-a-quote-form .part-numbers").val(rez.PartNumberCode);
            self.ModelJS.SavedUserConfigurationID = rez.SavedUserConfigurationID;
            $("#savedUserConfigurationId").html(rez.SavedUserConfigurationID);
            $("#btnSaveConfiguration").val(self.translations["Update configuration"]);
            self.ModelJS.DisableLastStationButtons = false;
            $scope.pinoutPdf();
            HideAjaxLoader();
          }).catch(function (result) {
            HideAjaxLoader();
          });
        });
      };

      $scope.$on('event_ResetConfigurator', function () {
        deleteCookie(vicTactonCookie);
        deleteCookie(vicCookie);
        deleteCookie(vicFamilyCookie);
        $.fancybox.close();
        var currentFamilies = self.ModelJS.Families;
        var initialFamilyId = self.ModelJS.InitialFamilyId;
        self.ModelJS = InitModelJS();
        self.ModelJS.Families = currentFamilies;
        setTimeout(function () {
          $('.match-height').css("height", "");
          window.matchColumnHeights();
        }, 10);
      }); //HideAjaxLoaderIfAllRequestsFinished

      $scope.SmartHideAjaxLoader = function () {
        if ($http.pendingRequests.length == 0) {
          HideAjaxLoader();
        }
      };

      $scope.getAccountId = function () {
        var id = parseInt($('#accountId').html());
        if (id === 0) id = -1;
        return id;
      };

      $scope.getSaveUserConfigurationKey = function () {
        return $("#savedUserConfigurationAccessKey").html();
      };

      $scope.updateConfigurationOwner = function () {
        var accountId = $scope.getAccountId();
        var sendToServerModelJS = CreateSendToServerUserConfigurationModelJS(accountId, self.ModelJS);
        AjaxService.SaveUserConfiguration(sendToServerModelJS).then(function (result) {
          var response = result.data;

          if (response !== -1) {
            self.ModelJS.SavedUserConfigurationID = response;
            setCookie(vicCookie, response, 2);
          }
        });
      };

      $scope.saveConfiguration = function () {
        var accountId = 0; //this goes to the server and retries the accountID - if we have a user logged in

        AjaxService.GetLoggedInAccountId(self.ModelJS.MarketCode, self.ModelJS.LanguageCode).then(function (result) {
          if (isNaN(parseFloat(result.data)) === false) {
            accountId = parseFloat(result.data);
            if (accountId === 0) accountId = -1;
          } //Save the configuration


          var sendToServerModelJS = CreateSendToServerUserConfigurationModelJS(accountId, self.ModelJS);
          AjaxService.SaveUserConfiguration(sendToServerModelJS).then(function (result) {
            var rez = result.data; // returns -1  for error or the newly created id! 

            if (rez == "-1") DisplaySaveConfigurationDialogVI(self.translations["There was an error saving the configuration, please try again"]);else {
              //Anonymous user saved configuration
              if (accountId === -1) {
                var loginUrl = '/' + self.ModelJS.MarketCode + '/' + self.ModelJS.LanguageCode + '/Login.aspx' + '?ReturnUrl=' + encodeURI('/vic.aspx?userConfigurationID=' + rez);
                DisplayLoginVI(self.translations["Login to save your configuration"], loginUrl);
              } else {
                DisplaySaveConfigurationDialogVI(self.translations["Configuration saved succesfully"]);
                self.ModelJS.SavedUserConfigurationID = rez; //if we just added it, we turn into Edit mode! 

                $("#savedUserConfigurationId").html(rez);
                $("#btnSaveConfiguration").val(self.translations["Update configuration"]);
              }
            }
            HideAjaxLoader();
          });
        }); //we test the productAccesories 
        //GetProductAccesories(self.ModelJS, AjaxService);
      };

      $scope.raiseDiscardEvent = function () {
        $scope.$emit('event_ResetConfigurator');
      };

      $scope.raiseEventPrintScreen = function () {
        $(".station-more-info").removeClass("collapse");
        window.print();
      };

      $scope.setupInJde = function () {
        ShowAjaxLoader();
        AjaxService.GetUserId(self.ModelJS.MarketCode, self.ModelJS.LanguageCode).then(function (result) {
          var userId = parseFloat(result.data);
          var accountId = $('#accountId').html();
          var basketId = $('#viBasketId').html();
          var configJs = CreateSendToServerUserConfigurationModelJS(accountId, self.ModelJS);
          AjaxService.SetupInJde(basketId, userId, configJs).then(function (result) {
            HideAjaxLoader();

            if (result.data) {
              self.ModelJS.ShowSetupButton = false;
              DisplayJdeSetupDialogVI('Vi setup success');
            } else {
              self.ModelJS.ShowSetupButton = true;
              DisplayJdeSetupDialogVI('Vi setup failure');
            }
          });
        });
      };

      $scope.pinoutPdf = function () {
        var showPinoutPdf = self.ModelJS.ShowPinOutPdfLink;
        var family = self.ModelJS.SelectedFamily.familyName;
        var itemCode = self.ModelJS.AttributesConfigCode + self.ModelJS.StationsConfigCode;
        var partNumber = self.ModelJS.PartNumberCode;
        var marketCode = self.ModelJS.MarketCode;
        var languageCode = self.ModelJS.LanguageCode;
        AjaxService.PinoutPdf(showPinoutPdf, family, itemCode, partNumber, marketCode, languageCode).then(function (result) {
          self.ModelJS.PinoutPdfContent = $sce.trustAsHtml(result.data.PinoutPdfContent);
          self.ModelJS.PinoutPdfUrl = $sce.trustAsResourceUrl(result.data.PinoutPdfUrl);
        });
      };
    }],
    templateUrl: "/mkt/ln/js/app/templates/mainController-template.html"
  });
  app.component('cadenas', {
    bindings: {
      list: '<',
      selected: '<',
      attributescode: '<',
      stationscode: '<',
      familyname: '<',
      atexselected: '<',
      stemimage: '<',
      translations: '<',
      partnumber: '<'
    },
    controller: ['$scope', '$sce', '$attrs', '$q', "$controller", function ($scope, $sce, $attrs, $q, $controller) {
      angular.extend(this, $controller('baseController', {
        $scope: $scope
      }));
      var self = this;
      self.translations = $scope.$parent.$ctrl.translations;
      $scope.showCad = false;
      self.cadURL;

      $scope.showButton = function (attributescode) {
        //TODO: Enquire when VS45 set up in Cad
        if (self.familyname === 'vs45') return false;

        if (attributescode) {
          return !attributescode.includes('+');
        }

        return false;
      };

      this.$onChanges = function (changes) {
        var isTacton = self.isTacton(self.familyname.toUpperCase());
        var cadDownloadString = "preview";
        if (self.attributescode !== null && !self.attributescode.includes('+')) cadDownloadString = "generation";
        var emailAddress = $("#emailAddress").html();
        if (emailAddress !== '') emailAddress = "&email=" + emailAddress;

        if (changes.attributescode || changes.stationscode) {
          if (changes.attributescode && changes.attributescode.currentValue && changes.attributescode.currentValue.indexOf('+') === -1 && $scope.showCad) {
            $scope.showCad = false;
          }

          self.cadURL = $sce.trustAsResourceUrl(window.gwsCadenasBaseDomain + "?info=imi_precision/6_valves/1_valve_islands/1_" + self.familyname + (isTacton ? "_6dig" : "") + "_asmtab.prj&varset={PNFINDER=1},{PNINPUT=" + self.attributescode + self.stationscode + "}&hidePortlets=navigation&hidePortlets=generation&showPortlets=" + cadDownloadString + emailAddress);
        }

        if (changes.partnumber && self.partnumber !== '' && self.partnumber !== undefined) {
          self.cadURL = $sce.trustAsResourceUrl(window.gwsCadenasBaseDomain + "?info=imi_precision/6_valves/1_valve_islands/1_" + self.familyname + (isTacton ? "_6dig" : "") + "_asmtab.prj&varset={PNFINDER=1},{PNINPUT=" + self.attributescode + self.stationscode + "},{CODE=" + self.partnumber + "}&hidePortlets=navigation&hidePortlets=generation&showPortlets=" + cadDownloadString + emailAddress);
        }
      };

      $scope.matchHeight = function () {
        setTimeout(function () {
          $('.match-height').css("height", "");
          window.matchColumnHeights();
        }, 0);
      };

      $scope.matchHeight();

      $scope.isComplete = function () {
        return self.attributesCode !== undefined && self.stationscode != '' && self.attributescode.indexOf('+') === -1 && self.stationscode.indexOf('+') === -1;
      };

      $scope.raiseEvent = function () {
        $scope.$emit('event_ResetConfigurator');
      };
    }],
    templateUrl: '/mkt/ln/js/app/templates/cadenas-template.html'
  });
  app.component('storefunctions', {
    bindings: {
      list: '<',
      selected: '<'
    },
    controller: ['$scope', '$attrs', '$q', "$controller", function ($scope, $attrs, $q, $controller) {
      angular.extend(this, $controller("baseController", {
        $scope: $scope
      }));
      var self = this;
      self.translations = $scope.$parent.$ctrl.translations;

      $scope.raiseBasketEvent = function () {
        $scope.$emit('event_AddToBasket');
      };
    }],
    templateUrl: '/mkt/ln/js/app/templates/storefunctions-template.html'
  });
  app.component('stationsList', {
    bindings: {
      stationintro: '<',
      stationtext: '<',
      list: '<',
      display: '<',
      displaystationindex: '<',
      repeatstationstext: '<',
      disablelaststationbuttons: '<',
      repeatbuttonenabled: '<',
      selectedfamilyid: '<',
      translations: '<'
    },
    controller: ['$scope', '$attrs', '$controller', function ($scope, $attrs, $controller) {
      angular.extend(this, $controller("baseController", {
        $scope: $scope
      }));
      var self = this;
      self.translations = $scope.$parent.$ctrl.translations;
    }],
    templateUrl: '/mkt/ln/js/app/templates/stationsList-template.html'
  });
  app.component('station', {
    bindings: {
      display: '<',
      id: '<',
      name: '<',
      list: '<',
      islast: '<',
      stationindex: '<',
      repeatstationstext: '<',
      stationlist: '<',
      stationintro: '<',
      stationtext: '<',
      disablelaststationbuttons: '<',
      repeatbuttonenabled: '<',
      selectedfamilyid: '<'
    },
    controller: ['$scope', '$attrs', '$q', '$sce', "$controller", function ($scope, $attrs, $q, $sce, $controller) {
      angular.extend(this, $controller("baseController", {
        $scope: $scope
      }));
      var self = this;
      self.translations = $scope.$parent.$ctrl.translations;

      $scope.raiseEvent = function (station, id) {
        var valid = true;
        station.StationAttributesList.forEach(function validateStationAttribute(attribute) {
          if (attribute.SelectedIndex !== -1) {
            attribute.isValid = true;
          } else {
            attribute.isValid = false;
            valid = false;
          }
        });

        if (valid) {
          $scope.$emit('event_StationAttributesCopy', {
            data: id
          });
        }
      };

      $scope.raiseEventPrevious = function () {
        $scope.$emit('event_Previous');
      };

      $scope.raiseEventNext = function (station) {
        var valid = true;
        station.StationAttributesList.forEach(function validateStationAttribute(attribute) {
          if (attribute.SelectedIndex !== -1) {
            attribute.isValid = true;
          } else {
            attribute.isValid = false;
            valid = false;
          }
        });

        if (valid) {
          $scope.$emit('event_NextStation');
        }
      };
    }],
    templateUrl: '/mkt/ln/js/app/templates/station-template.html'
  });
  app.component('attributesList', {
    bindings: {
      list: '<',
      display: '<',
      currentnavtype: '<',
      selectedfamilyid: '<',
      translations: '<'
    },
    controller: ['$scope', '$attrs', '$sce', '$q', "$controller", function ($scope, $attrs, $sce, $q, $controller) {
      angular.extend(this, $controller('baseController', {
        $scope: $scope
      }));
      var self = this;
      self.translations = $scope.$parent.$ctrl.translations;

      $scope.raiseEventPrevious = function () {
        $scope.$emit('event_Previous');
      };

      $scope.raiseEventNext = function (manifoldAttributes) {
        var valid = true;
        manifoldAttributes.forEach(function ValidateStemAttribute(attrib) {
          if (attrib.SelectedIndex === -1) {
            attrib.isValid = false;
            valid = false;
          } else {
            attrib.isValid = true;
          }
        });

        if (valid) {
          $scope.$emit('event_NextAttributes');
        }
      };
    }],
    templateUrl: '/mkt/ln/js/app/templates/attributesList-template.html'
  });
  app.component('stationAttributesList', {
    bindings: {
      stationintro: '<',
      stationtext: '<',
      list: '<',
      stationindex: '<',
      repeatstationstext: '<',
      stationlist: '<',
      selectedfamilyid: '<'
    },
    controller: ['$scope', '$attrs', '$q', "$controller", function ($scope, $attrs, $q, $controller) {
      angular.extend(this, $controller("baseController", {
        $scope: $scope
      }));
      var self = this;
      self.translations = $scope.$parent.$ctrl.translations;

      $scope.raiseStationChange = function (stationId) {
        $scope.$emit('event_NavigateStations', {
          data: {
            stationId: stationId
          }
        });
        $scope.$emit('event_StationAttributeChangeEvent', {
          data: {
            stationIndex: stationId
          }
        });
      };
    }],
    templateUrl: '/mkt/ln/js/app/templates/stationAttributesList-template.html'
  });
  app.component('stationAttribute', {
    bindings: {
      id: '<',
      name: '<',
      tip: '<',
      list: '<',
      selected: '<',
      selectedindex: '=',
      stationindex: '<',
      repeatstationstext: '<',
      isValid: '<',
      translations: '<',
      stationlist: '<',
      selectedfamilyid: '<'
    },
    controller: ['$scope', '$sce', '$attrs', '$q', "$controller", function ($scope, $sce, $attrs, $q, $controller) {
      angular.extend(this, $controller("baseController", {
        $scope: $scope
      }));
      var self = this;
      self.isValid = self.isValid || true;
      self.translations = $scope.$parent.$ctrl.translations;
      self.fancyboxid = GenerateGuid();

      $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
      };

      $scope.raiseStationAttributeChangeEvent = function (attributeID, attribute, stationIndex, attributeName, currentStation) {
        attribute = attribute || {}; //raises an Event that will be captured on the main component

        $scope.$emit('event_StationAttributeChangeEvent', {
          attributeID: attributeID,
          attributeValueID: attribute.attributeValueID,
          attributeValueName: attribute.tagText,
          attributeName: attributeName,
          stationIndex: stationIndex
        });
      };
    }],
    templateUrl: '/mkt/ln/js/app/templates/stationAttribute-template.html'
  });
  app.component('familyList', {
    bindings: {
      list: '<',
      selected: '<',
      display: '<'
    },
    controller: ['$scope', '$attrs', '$q', '$sce', "$controller", function ($scope, $attrs, $q, $sce, $controller) {
      angular.extend(this, $controller("baseController", {
        $scope: $scope
      }));
      var self = this;
      self.translations = $scope.$parent.$ctrl.translations;

      $scope.raiseEvent = function (id) {
        //raises an Event that will be captured on the main component
        $scope.$emit('event_FamilyChanged', {
          data: id
        });
      };

      $scope.raiseEventPrevious = function () {
        $scope.$emit('event_Previous');
      };

      $scope.raiseEventNext = function () {
        $scope.$emit('event_Next');
      };
    }],
    templateUrl: '/mkt/ln/js/app/templates/familyList-template.html'
  });
  app.component('controlOptionList', {
    bindings: {
      controldisplay: '<',
      controllist: '<',
      controlselected: '<',
      controlselectedindex: '<',
      controltagtext: '<',
      controltip: '<',
      atexdisplay: '<',
      atexlist: '<',
      atexselected: '<',
      atexselectedindex: '<',
      atextagtext: '<',
      list: '<',
      currentnavtype: '<',
      selected: '<'
    },
    controller: ['$scope', '$sce', '$attrs', '$q', "$controller", "tactonAjaxService", function ($scope, $sce, $attrs, $q, $controller, tactonAjaxService) {
      angular.extend(this, $controller("baseController", {
        $scope: $scope
      }));
      var self = this;
      self.translations = $scope.$parent.$ctrl.translations;
      self.fancyboxid = GenerateGuid();
      self.atexfancyboxid = GenerateGuid();
      self.atexConfirmed = false; //we need to retrieve a currentStep
      //how do we get this?

      $scope.renderHtml = function (html_code) {
        if (html_code === undefined) {
          return '';
        }

        return $sce.trustAsHtml(html_code.toLocaleString());
      };

      $scope.raiseControlEvent = function (attributeID, attributeValue, attributeName) {
        attributeValue = attributeValue || {}; //raises an Event that will be captured on the main component

        $scope.$emit('event_ControlOptionChanged', {
          attributeID: attributeID,
          attributeValueID: attributeValue.attributeValueID,
          attributeValueName: attributeValue.tagText,
          attributeName: attributeName
        });
      };

      $scope.raiseAtexEvent = function (attributeID, attributeValue, attributeName) {
        if (attributeValue.attributeValueID === 3) {
          $.fancybox.open($('#atex-confirm'), {
            'hideOnOverlayClick': false,
            width: 450,
            height: 'auto',
            openEffect: 'none',
            closeEffect: 'none',
            centerOnScroll: true,
            'autoSize': false,
            helpers: {
              overlay: {
                closeClick: false
              }
            },
            'closeBtn': false,
            closeClick: false,
            beforeClose: function beforeClose() {
              if (!$scope.$parent.$ctrl.atexConfirmed) {
                $scope.$emit('event_AtexChanged', {
                  attributeID: attributeID,
                  attributeValueID: 2,
                  attributeValueName: attributeValue.tagText,
                  attributeName: attributeName
                });
              }

              return true;
            }
          });
        }

        $scope.$emit('event_AtexChanged', {
          attributeID: attributeID,
          attributeValueID: attributeValue.attributeValueID,
          attributeValueName: attributeValue.tagText,
          attributeName: attributeName
        });
      };

      $scope.raiseEventPrevious = function () {
        $scope.$emit('event_Previous');
      };

      $scope.raiseEventNext = function (controlAttribute, stemAttributes) {
        var valid = true; //lets do some basic validation here shall we?

        if (!controlAttribute.hasOwnProperty("attributeValueID") || controlAttribute.attributeValueID === -1) {
          controlAttribute.isValid = false;
          valid = false;
        } else {
          controlAttribute.isValid = true;
        }

        stemAttributes.forEach(function ValidateStemAttribute(attrib) {
          if ((attrib.AttributeID === 11 || attrib.AttributeID === 15 || attrib.AttributeID === 17) && attrib.SelectedIndex === -1) {
            attrib.isValid = false;
            valid = false;
          } else {
            attrib.isValid = true;
          }
        });

        if (valid) {
          $scope.$emit('event_NextControlOption', {
            attributeValueID: controlAttribute.attributeValueID
          });
        }
      };
    }],
    templateUrl: '/mkt/ln/js/app/templates/controlOptionList-template.html'
  });
  app.component('attribute', {
    bindings: {
      id: '<',
      name: '<',
      tip: '<',
      list: '<',
      selected: '<',
      selectedindex: '<',
      currentnavtype: '<',
      isValid: '<',
      translations: '<',
      selectedfamilyid: '<'
    },
    controller: ['$scope', '$sce', '$attrs', '$q', "$controller", function ($scope, $sce, $attrs, $q, $controller) {
      angular.extend(this, $controller("baseController", {
        $scope: $scope
      }));
      var self = this;
      self.isValid = self.isValid || true;
      self.translations = $scope.$parent.$ctrl.translations;
      self.fancyboxid = GenerateGuid();
      self.tooltipClass = "";

      $scope.toggle = function (classname) {
        if (classname) {
          self.tooltipClass = "";
        } else {
          self.tooltipClass = "active";
        }
      };

      $scope.renderHtml = function (html_code) {
        if (html_code === undefined) {
          return '';
        }

        return $sce.trustAsHtml(html_code);
      };

      $scope.raiseAttributeChangeEvent = function (attributeID, attributeValue, attributeName) {
        attributeValue = attributeValue || {};
        $scope.$emit('event_AttributeChangeEvent', {
          attributeID: attributeID,
          attributeValueID: attributeValue.attributeValueID,
          attributeValueName: attributeValue.tagText,
          attributeName: attributeName,
          attributeValueDescription: attributeValue.attributeValueDescription
        });
      };
    }],
    templateUrl: '/mkt/ln/js/app/templates/attribute-template.html'
  });
  app.component('accessoryPriceList', {
    bindings: {
      list: '='
    },
    controller: ['$scope', '$attrs', '$controller', function ($scope, $attrs, $controller) {
      angular.extend(this, $controller("baseController", {
        $scope: $scope
      }));
      var self = this;
    }],
    templateUrl: '/mkt/ln/js/app/templates/accessoryPriceList-template.html'
  });
  app.component('accessoryPrice', {
    bindings: {
      stockCode: '<',
      description: '<',
      quantity: '<',
      list: '<',
      shipping: '<',
      listPrice: '<'
    },
    controller: ['$scope', '$attrs', '$controller', function ($scope, $attrs, $controller) {
      angular.extend(this, $controller("baseController", {
        $scope: $scope
      }));
      var self = this;
    }],
    templateUrl: '/mkt/ln/js/app/templates/accessoryPrice-template.html'
  });
  app.component('productAccesoryList', {
    bindings: {
      list: '=',
      display: '<'
    },
    controller: ['$scope', '$attrs', '$controller', function ($scope, $attrs, $controller) {
      angular.extend(this, $controller("baseController", {
        $scope: $scope
      }));
      var self = this;
      self.translations["Select accessories"] = window.translation.SelectAccessories;
      self.translations["Accessory"] = window.translation.Accessory;
      self.translations["Quantity"] = window.translation.Quantity;
      self.translations["price and availability"] = window.translation.PriceAndAvailability;
      self.translations["Add To Basket"] = window.translation.AddToBasket;

      $scope.raisePandAEvent = function () {
        //raises an Event that will be captured on the main component
        $scope.$emit('event_ProductAccesoryListEvent', {
          data: {}
        });
      };

      $scope.raiseAddAccessoriesToBasketEvent = function () {
        //raises an Event that will be captured on the main component
        $scope.$emit('event_ProductAccesoryAddToBasketEvent', {
          data: {}
        });
      };
    }],
    templateUrl: '/mkt/ln/js/app/templates/productAccesoryList-template.html'
  });
  app.component('productAccesory', {
    bindings: {
      code: '<',
      name: '<',
      quantity: '=',
      panda: '<'
    },
    controller: ['$scope', '$attrs', '$controller', function ($scope, $attrs, $controller) {
      angular.extend(this, $controller("baseController", {
        $scope: $scope
      }));
      var self = this;
    }],
    templateUrl: '/mkt/ln/js/app/templates/productAccesory-template.html'
  });
  app.component('navigation', {
    bindings: {
      list: '<',
      currentnavigationindex: '<',
      model: '<'
    },
    controller: ['$scope', '$attrs', '$controller', function ($scope, $attrs, $controller) {
      angular.extend(this, $controller("baseController", {
        $scope: $scope
      }));
      var self = this;

      $scope.raiseNavigationChangeEvent = function (index) {
        if (!$scope.$ctrl.isTacton($scope.$ctrl.model.SelectedFamily.familyName)) index = GetValidNavigationIndex(index, $scope.$ctrl.model); //raises an Event that will be captured on the main component

        $scope.$emit('event_NavigationChangeEvent', {
          data: {
            index: index
          }
        });
      };
    }],
    templateUrl: '/mkt/ln/js/app/templates/navigation-template.html'
  });
  app.component('islandSummary', {
    bindings: {
      display: '<',
      model: '=',
      translations: '<'
    },
    controller: ['$scope', '$attrs', '$q', "$controller", function ($scope, $attrs, $q, $controller) {
      angular.extend(this, $controller("baseController", {
        $scope: $scope
      }));
      var self = this;
      self.translations = $scope.$parent.$ctrl.translations;

      $scope.raiseEventExpandAllStations = function () {
        $(".station-more-info").removeClass("collapse");
      };
    }],
    templateUrl: '/mkt/ln/js/app/templates/islandSummary-template.html'
  });
  app.directive('imageonload', function () {
    return {
      restrict: 'A',
      link: function link(scope, element, attrs) {
        element.bind('load', function () {
          $('.match-height').css("height", "");
          window.matchColumnHeights();
        });
      }
    };
  });

  function InititaliseService($http) {
    var service = this; //functions

    service.GetFamilies = function () {
      var request = {
        url: '/api/ValveIsland/Families',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          MarketCode: window.gwsMc,
          LanguageCode: window.gwsLc,
          CountryCode: window.gwsCc
        }
      };
      return $http(request);
    };

    service.ChangeFamily = function (id) {
      ShowAjaxLoader();
      var request = {
        url: '/api/ValveIsland/ChangeFamily',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          MarketCode: window.gwsMc,
          LanguageCode: window.gwsLc,
          CountryCode: window.gwsCc,
          Id: id
        }
      };
      return $http(request);
    };

    service.ChangeAtexOrControlOption = function (selectedModelJS) {
      ShowAjaxLoader();
      var request = {
        url: '/api/ValveIsland/ChangeAtexOrControlOption',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: selectedModelJS
      };
      return $http(request);
    };

    service.ChangeStemAttributeValue = function (currentSelectedAttribute, selectedModelJS) {
      ShowAjaxLoader();
      var request = {
        url: '/api/ValveIsland/ChangeStemAttributeValue',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          CurrentSelectedAttribute: currentSelectedAttribute,
          Model: selectedModelJS
        }
      };
      return $http(request);
    };

    service.ChangeStationAttributeValue = function (selectedModelJS) {
      ShowAjaxLoader();
      var request = {
        url: '/api/ValveIsland/ChangeStationAttributeValue',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: selectedModelJS
      };
      return $http(request);
    };

    service.SaveUserConfiguration = function (selectedModelJS) {
      ShowAjaxLoader();
      var request = {
        url: '/api/ValveIsland/SaveUserConfiguration',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: selectedModelJS
      };
      return $http(request);
    }; //new methods from victor


    service.GetConfigurationCodeForStations = function (selectedModelJS) {
      ShowAjaxLoader();
      var request = {
        url: '/api/ValveIsland/GetConfigurationCodeForStations',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: selectedModelJS
      };
      return $http(request);
    };

    service.GetListOfProductAccessories = function (selectedModelJS) {
      ShowAjaxLoader();
      var request = {
        url: '/api/ValveIsland/GetListOfProductAccessories',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: selectedModelJS
      };
      return $http(request);
    };

    service.RetrieveSavedUserConfiguration = function (savedConfigId) {
      ShowAjaxLoader();
      var request = {
        url: '/api/ValveIsland/RetrieveSavedUserConfiguration',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          SavedConfigId: savedConfigId,
          MarketCode: window.gwsMc,
          LanguageCode: window.gwsLc,
          CountryCode: window.gwsCc
        }
      };
      return $http(request);
    };

    service.RetrieveSavedPartNumberConfiguration = function (partNumber) {
      ShowAjaxLoader();
      var request = {
        url: '/api/ValveIsland/RetrieveSavedPartNumberConfiguration',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          PartNumber: partNumber,
          MarketCode: window.gwsMc,
          LanguageCode: window.gwsLc,
          CountryCode: window.gwsCc
        }
      };
      return $http(request);
    };

    service.GetLoggedInAccountId = function (gwsMc, gwsLc) {
      ShowAjaxLoader();
      var url = '/' + gwsMc + '/' + gwsLc + '/service/getloggedinaccountid/ajax.aspx';
      var request = {
        url: url,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {}
      };
      return $http(request);
    };

    service.GetUserId = function (gwsMc, gwsLc) {
      var url = '/' + gwsMc + '/' + gwsLc + '/service/GetUserId/ajax.aspx';
      var request = {
        url: url,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {}
      };
      return $http(request);
    };

    service.PinoutPdf = function (showPinoutPdf, family, itemCode, partNumber, marketCode, languageCode) {
      var request = {
        url: '/api/ValveIsland/PinoutPdf',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          ShowPinoutPdf: showPinoutPdf,
          Family: family,
          ItemCode: itemCode,
          PartNumber: partNumber,
          MarketCode: marketCode,
          LanguageCode: languageCode
        }
      };
      return $http(request);
    };

    service.IsValidPartRetrieval = function (configCode, partId) {
      var request = {
        url: '/api/ValveIsland/IsValidPartRetrieval',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          MarketCode: window.gwsMc,
          LanguageCode: window.gwsLc,
          CountryCode: window.gwsCc,
          ConfigCode: configCode,
          PartId: partId
        }
      };
      return $http(request);
    };

    service.AddToBasket = function (basketId, itemCode, js, configJs) {
      var request = {
        url: '/api/ValveIsland/AddToBasket',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          MarketCode: window.gwsMc,
          LanguageCode: window.gwsLc,
          CountryCode: window.gwsCc,
          id: basketId,
          itemCode: itemCode,
          modelJS: js,
          configJs: configJs
        }
      };
      return $http(request);
    };

    service.ConvertToProducts = function (selectedModelJS) {
      var request = {
        url: '/api/ValveIsland/ConvertToProducts',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          modelJS: selectedModelJS
        }
      };
      return $http(request);
    };

    service.GetValvePandA = function (selectedModelJS, configJs) {
      var request = {
        url: '/api/ValveIsland/GetValvePandA',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          modelJS: selectedModelJS,
          configJs: configJs
        }
      };
      return $http(request);
    };

    service.GetAccessoriesPandA = function (selectedModelJS) {
      var request = {
        url: '/api/ValveIsland/GetAccessoriesPandA',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: selectedModelJS
      };
      return $http(request);
    };

    service.AccessoriesAddToBasket = function (selectedModelJS) {
      var request = {
        url: '/api/ValveIsland/AccessoriesAddToBasket',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: selectedModelJS
      };
      return $http(request);
    };

    service.Documents = function () {
      var request = {
        url: '/api/ValveIsland/Documents',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          MarketCode: window.gwsMc,
          LanguageCode: window.gwsLc,
          CountryCode: window.gwsCc
        }
      };
      return $http(request);
    };

    service.SetupInJde = function (basketId, userId, configJs) {
      var request = {
        url: '/api/ValveIsland/SetupInJde',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          basketId: basketId,
          userId: userId,
          configJs: configJs
        }
      };
      return $http(request);
    };

    service.CheckSolenoids = function (model, station) {
      var request = {
        url: '/api/ValveIsland/CheckSolenoids',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          model: model,
          station: station
        }
      };
      return $http(request);
    };

    return service;
  }

  function initialiseNavigation(translations, addAccessoriesTab) {
    var navigationList = [];
    navigationList.push({
      Index: 0,
      Tagtext: translations['Valve Island'],
      Type: 1,
      Completed: false
    });
    navigationList.push({
      Index: 1,
      Tagtext: translations['Control option nav'],
      Type: 2,
      Completed: false
    });
    navigationList.push({
      Index: 2,
      Tagtext: translations['Manifold nav'],
      Type: 3,
      Completed: false
    });
    navigationList.push({
      Index: 3,
      Tagtext: translations['Stations nav'],
      Type: 4,
      Completed: false
    });

    if (addAccessoriesTab) {
      navigationList.push({
        Index: 4,
        Tagtext: translations['Accessories nav'],
        Type: 6,
        Completed: false
      });
      navigationList.push({
        Index: 5,
        Tagtext: translations['Complete nav'],
        Type: 5,
        Completed: false
      });
    } else {
      navigationList.push({
        Index: 4,
        Tagtext: translations['Complete nav'],
        Type: 5,
        Completed: false
      });
    }

    return navigationList;
  }

  function isSectionComplete(type, model) {
    var complete = false;

    switch (type) {
      case 1:
        //Valve Island section
        return model.SelectedFamily && model.SelectedFamily.hasOwnProperty("familyID");

      case 2:
        //Control option section
        complete = (!model.DisplayAtexApproval || model.SelectedAtexApproval.hasOwnProperty("attributeValueID")) && (!model.DisplayControlOptions || model.SelectedControlOptions && model.SelectedControlOptions.hasOwnProperty("attributeValueID"));
        if (model.StemAttributesList == undefined) return false;

        for (var i = 0; i < model.StemAttributesList.length; i++) {
          var attrib = model.StemAttributesList[i];

          if ((attrib.AttributeID === 11 || attrib.AttributeID === 15 || attrib.AttributeID === 17) && attrib.SelectedIndex == -1) {
            complete = false;
          }
        }

        return complete;

      case 3:
        //Manifold section
        if (model.StemAttributesList == null || model.StemAttributesList.length == 0) return false;

        for (var _i = 0; _i < model.StemAttributesList.length; _i++) {
          if (model.StemAttributesList[_i].AttributeID !== 5 && model.StemAttributesList[_i].AttributeID !== 18 && model.StemAttributesList[_i].SelectedIndex == -1) {
            return false;
          }
        }

        return true;

      case 4:
        //Stations
        if (model.StationsList !== null) {
          if (model.StationsList.length > 0) {
            for (var _i2 = 0; _i2 < model.StationsList.length; _i2++) {
              complete = isStationComplete(model.StationsList[_i2]);
              if (complete == false) break;
            }
          }
        }

        return complete;

      default:
        return false;
    }
  }

  function isStationComplete(station) {
    var valid = true;
    station.StationAttributesList.forEach(function validateStationAttribute(attribute) {
      if (attribute.SelectedIndex === -1) {
        valid = false;
      }
    });
    return valid;
  }

  function BuildNavigation(model, translations, addAccessoriesTab) {
    var navigationList = initialiseNavigation(translations, addAccessoriesTab);
    var selectedSectionIndex = 0;

    for (var i = 0; i < navigationList.length; i++) {
      var currentElement = navigationList[i];
      currentElement.Completed = isSectionComplete(currentElement.Type, model);
    }

    model.NavigationList = navigationList;
  }

  function GetValidNavigationIndex(newIndex, model) {
    //Check to make sure the navigation change request is valid, if not return the original index.
    if (newIndex <= model.CurrentNavigationIndex) {
      return newIndex;
    }

    if (newIndex == model.CurrentNavigationIndex + 1 && isSectionComplete(model.CurentNavigationTypeToDisplay, model)) {
      return newIndex;
    }

    if (isSectionComplete(newIndex, model)) {
      return newIndex;
    }

    if (model.translations) displayDialogBox(model.translations["Please fill in all fields on the form"]);
    return model.CurrentNavigationIndex;
  }

  function BuildNavigationForProductAccesories(model) {
    var index = model.NavigationList.length - 1; //first i check if i already have added the accesories 

    var last = model.NavigationList[index - 1];

    if (last.Type != 6) {
      if (model.hasOwnProperty("ProductAccesoryList")) {
        if (model.ProductAccesoryList.length > 0) {
          index = index + 1; //we show it as completed if all values have a > 0 value in quantity

          var completedAccesory = true;

          for (var i = 0; i < model.ProductAccesoryList.length; i++) {
            if (model.ProductAccesoryList[i].quantity == 0) {
              completedAccesory = false;
              break;
            }
          }

          var element = {
            Index: index,
            Tagtext: self.translations["Accesories"],
            Type: 6,
            Completed: completedAccesory
          };
          NavigationList.push(element);
        }
      }
    }

    HideAjaxLoader();
  }

  function InitModelJS() {
    return {
      MarketCode: window.gwsMc,
      LanguageCode: window.gwsLc,
      CountryCode: window.gwsCc,
      StemID: 0,
      SavedUserConfigurationID: 0,
      AttributesConfigCode: "",
      StationsConfigCode: "",
      Families: [],
      SelectedFamily: {},
      DisplayAtexApproval: false,
      SelectedAtexApproval: {},
      AtexApprovals: [],
      DisplayControlOptions: false,
      ControlOptions: [],
      SelectedControlOptions: {},
      StemAttributesList: [],
      SelectedAttributesAndValuesList: [],
      StationsList: [],
      NoOfIsemStations: 0,
      CurrentNavigationIndex: 0,
      CurrentStationIndex: -1,
      CurentNavigationTypeToDisplay: 1,
      IsAdmin: $('#isAdmin').html().toLowerCase() === "true",
      AllowAnonPrice: $('#anonPrice').html(),
      ChangedAttributeId: 0,
      HideBom: $('#hideBom').html(),
      Quantity: 1
    };
  }

  function InitModelJSForStemAttributes(model) {
    model.AttributesConfigCode = "";
    model.StationsConfigCode = "";
    model.StemID = 0;
    model.SelectedControlOptions = {};
    model.StemAttributesList = [];
    model.StationsList = [];
    model.NoOfIsemStations = 0; //SelectedAttributesAndValuesList: []
    //comntrol option: RemoveFromSelectedAttributesAndValuesList(list, 1);

    return model;
  } //we only send a subset of our ModelJS


  function CreateSendToServerModelJS(model) {
    var accountId = $('#accountId').html();
    var norgrenAccountId = $('#norgrenAccountId').html();
    var configCode = model.AttributesConfigCode + model.StationsConfigCode; //we get the list of stations without the SelectedStationAttributesAndValuesList - which is large and useless for us at this point. 

    var newStationsList = ExtractStationListWithoutAttributesList(model.StationsList, 0);
    var noOfIsemStations = GetNoOfIsemStations(model);

    if (Array.isArray(model.StationsList)) {
      var prevStationsSelectedAttributesAndValues = model.StationsList.slice(0, 0).map(function (station) {
        return {
          StationAttributes: station.SelectedStationAttributesAndValuesList
        };
      });
    }

    var newModel = {
      MarketCode: model.MarketCode,
      LanguageCode: model.LanguageCode,
      CountryCode: model.CountryCode,
      AccountID: accountId,
      NorgrenAccountID: norgrenAccountId,
      ConfigCode: configCode,
      SelectedFamilyId: model.SelectedFamily.familyID,
      SelectedSeries: model.SelectedFamily.series,
      SelectedAttributesAndValuesList: model.SelectedAttributesAndValuesList,
      StationsList: newStationsList,
      NoOfIsemStations: noOfIsemStations,
      PrevStationsSelectedAttributesAndValues: prevStationsSelectedAttributesAndValues,
      SavedUserConfigurationID: model.SavedUserConfigurationID,
      Products: model.Products,
      TactonBomShortCode: model.TactonBomShortCode
    };
    return newModel;
  } //we extract the stations as well if they exist - basically we get the list of stations without the StationAttributesList - which is large and useless for us at this point.


  function ExtractStationListWithoutAttributesList(StationsList, fromIndex) {
    var newStationsList = new Array();
    if (!Array.isArray(StationsList)) return;

    for (var i = fromIndex; i < StationsList.length; i++) {
      var station = StationsList[i];
      var newStation = {
        StationIndex: station.StationIndex,
        TagText: station.TagText,
        ConfigCode: station.ConfigCode,
        StationAttributesList: [],
        SelectedStationAttributesAndValuesList: station.SelectedStationAttributesAndValuesList,
        IsTheLastOne: station.IsTheLastOne,
        IsIsem: station.IsIsem
      };
      newStationsList.push(newStation);
    }

    return newStationsList;
  } //we extract the stations as well if they exist - basically we get the list of stations with the StationAttributesList but without the values (all the possible attribute values)


  function ExtractStationListWithAttributesListButWithoutListOfAttributesValues(StationsList, fromIndex) {
    var newStationsList = new Array();

    for (var i = fromIndex; i < StationsList.length; i++) {
      var station = StationsList[i];
      var newAttributesList = ExtractAttributesListWithoutTheListOfValues(station.StationAttributesList);
      var newStation = {
        StationIndex: station.StationIndex,
        TagText: station.TagText,
        ConfigCode: station.ConfigCode,
        StationAttributesList: newAttributesList,
        SelectedStationAttributesAndValuesList: station.SelectedStationAttributesAndValuesList,
        IsTheLastOne: station.IsTheLastOne,
        IsIsem: station.IsIsem
      };
      newStationsList.push(newStation);
    }

    return newStationsList;
  }

  function GetNoOfIsemStations(model) {
    var totalIsemStations = 0;
    if (!Array.isArray(model.StationsList)) return 0;

    for (var i = 0; i < model.StationsList.length - 1; i++) {
      if (model.StationsList[i].IsIsem === true) totalIsemStations++;
    }

    var portsPositionAttribute = model.SelectedAttributesAndValuesList.find(function (element) {
      return element.attributeID === 35;
    });

    if (portsPositionAttribute !== undefined) {
      if (portsPositionAttribute.attributeValueName.toLowerCase().includes('both')) {
        totalIsemStations += 2;
      } else {
        totalIsemStations++;
      }
    }

    return totalIsemStations;
  }
  /**
   * Get the number of stations that have the attribute Valve Function still with the default value of -1
   * @param {any} StationsList
   */


  function GetUnconfiguredValveFunctionStations(StationsList) {
    var VALVE_FUNCTION_ID = 6;
    var noOfDefaultValveFunctionStations = StationsList.length;

    for (var i = 0; i < StationsList.length - 1; i++) {
      for (var j = 0; j < StationsList[i].SelectedStationAttributesAndValuesList.length; j++) {
        if (StationsList[i].SelectedStationAttributesAndValuesList[j].attributeID === VALVE_FUNCTION_ID) {
          noOfDefaultValveFunctionStations--;
        }
      }
    }

    return noOfDefaultValveFunctionStations;
  }

  function ExtractAttributesListWithoutTheListOfValues(StationAttributesList) {
    var newStationAttributesList = new Array();

    for (var i = 0; i < StationAttributesList.length; i++) {
      var stationAttribute = StationAttributesList[i];
      var newStationAttribute = {
        AttributeID: stationAttribute.AttributeID,
        TagText: stationAttribute.TagText,
        SelectedIndex: stationAttribute.SelectedIndex,
        List: []
      };
      newStationAttributesList.push(newStationAttribute);
    }

    return newStationAttributesList;
  } //we create a model for only changes in 1 station


  function CreateSendToServerOneStationModelJS(model, StationIndex) {
    var newModel = {
      StemID: model.StemID,
      MarketCode: model.MarketCode,
      LanguageCode: model.LanguageCode,
      CountryCode: model.CountryCode,
      SelectedFamilyId: model.SelectedFamily.familyID,
      SelectedAttributesAndValuesList: model.SelectedAttributesAndValuesList,
      Station: model.StationsList[StationIndex],
      PrevStationsSelectedAttributesAndValues: model.StationsList.slice(0, StationIndex).map(function (station) {
        return {
          StationAttributes: station.SelectedStationAttributesAndValuesList
        };
      }),
      ChangedAttributeId: model.ChangedAttributeId,
      NoOfIsemStations: GetNoOfIsemStations(model),
      NoOfUnconfiguredValveFunctionStations: GetUnconfiguredValveFunctionStations(model.StationsList)
    };
    return newModel;
  }

  function CreateSendToServerMultipleStationsModelJS(model, index) {
    //var newStationList = ExtractStationListWithAttributesListButWithoutListOfAttributesValues(model.StationsList, index + 1);
    var newModel = {
      StemID: model.StemID,
      MarketCode: model.MarketCode,
      LanguageCode: model.LanguageCode,
      CountryCode: model.CountryCode,
      SelectedFamilyId: model.SelectedFamily.familyID,
      SelectedAttributesAndValuesList: model.SelectedAttributesAndValuesList,
      StationsList: model.StationsList,
      NoOfIsemStations: GetNoOfIsemStations(model),
      NoOfUnconfiguredValveFunctionStations: GetUnconfiguredValveFunctionStations(model.StationsList)
    };
    return newModel;
  } //we only send a subset of our ModelJS - we add the SelectedAttributesAndValuesList and the Stations selectedLists


  function CreateSendToServerModelJSForProductAccesories(model) {
    //we get the list of stations without the SelectedStationAttributesAndValuesList - which is large and useless for us at this point. 
    var newStationsList = ExtractStationListWithoutAttributesList(model.StationsList, 0);
    var noOfIsemStations = GetNoOfIsemStations(model);
    var stationsContainingValveFunctionAndNotConfigured = GetUnconfiguredValveFunctionStations(model.StationsList);
    var newModel = {
      MarketCode: model.MarketCode,
      LanguageCode: model.LanguageCode,
      CountryCode: model.CountryCode,
      StemID: model.StemID,
      SelectedFamilyId: model.SelectedFamily.familyID,
      SelectedAttributesAndValuesList: model.SelectedAttributesAndValuesList,
      StationsList: newStationsList,
      NoOfIsemStations: noOfIsemStations,
      NoOfUnconfiguredValveFunctionStations: stationsContainingValveFunctionAndNotConfigured
    };
    return newModel;
  }

  function CreateSendToServerModelJSForAccessoriesPandA(model) {
    var baskId = $('#viBasketId').html();
    var accountId = $('#accountId').html();
    var newModel = {
      MarketCode: model.MarketCode,
      LanguageCode: model.LanguageCode,
      CountryCode: model.CountryCode,
      BasketId: baskId,
      AccountId: accountId,
      ProductAccesoryList: model.ProductAccesoryList
    };
    return newModel;
  } //this will be sent to the server for the user configuration


  function CreateSendToServerUserConfigurationModelJS(accountID, model) {
    var newStationList = ExtractStationListWithoutAttributesList(model.StationsList, 0);
    var noOfIsemStations = GetNoOfIsemStations(model);
    var configCode = model.AttributesConfigCode + model.StationsConfigCode;
    var norgrenAccountId = $('#norgrenAccountId').html();
    var newModel = {
      AccountID: accountID,
      NorgrenAccountId: norgrenAccountId,
      ConfigurationCode: configCode,
      SavedUserConfigurationID: model.SavedUserConfigurationID,
      UserConfigurationModelJS: {
        StemID: model.StemID,
        MarketCode: model.MarketCode,
        LanguageCode: model.LanguageCode,
        CountryCode: model.CountryCode,
        FamilyID: model.SelectedFamily.familyID,
        SelectedAttributesAndValuesList: model.SelectedAttributesAndValuesList,
        StationList: newStationList,
        NoOfIsemStations: noOfIsemStations
      },
      Products: model.Products,
      PartNumberCode: model.PartNumberCode,
      Quantity: model.Quantity
    };
    return newModel;
  }

  function GetTheConfigCodeFromTheStations(StationsList, untilIndex) {
    var configCode = "";

    for (var i = 0; i < untilIndex; i++) {
      var station = StationsList[i];
      configCode = configCode + station.ConfigCode;
    }

    return configCode;
  }

  function AddToSelectedAttributesAndValuesList(selectedAttributes, attributeData) {
    if (!Array.isArray(selectedAttributes)) {
      selectedAttributes = [];
    }

    var index = selectedAttributes.map(function (e) {
      return e.attributeID;
    }).indexOf(attributeData.attributeID);

    if (index !== -1) {
      selectedAttributes[index] = attributeData;
    } else {
      selectedAttributes.push(attributeData);
    }

    return selectedAttributes;
  }

  function RemoveFromSelectedAttributesAndValuesList(list, attributeID) {
    for (var i = list.length - 1; i >= 0; i--) {
      if (list[i].attributeID == attributeID) {
        list.splice(i, 1);
        return list;
      }
    }

    return list;
  } //will return the index of the component with the selected ID


  function getIndexForId(list, ID) {
    for (var i = 0; i < list.length; i++) {
      if (list[i].attributeValueID == ID) {
        return i;
      }
    }

    return -1;
  }

  function getFamilyIndexForId(list, ID) {
    for (var i = 0; i < list.length; i++) {
      if (list[i].familyID == ID) {
        return i;
      }
    }

    return -1;
  }

  function getIndexIForStationAttributeId(list, attributeID) {
    for (var k = 0; k < list.length; k++) {
      var element = list[k];
      if (element.AttributeID == attributeID) return k;
    }

    return -1;
  } //populates or empties the list of ProductAccesories


  function GetProductAccesories(model, AjaxService) {
    var getAccesories = false;

    if (model.AttributesConfigCode !== null && model.AttributesConfigCode.length > 0) {
      var str = model.AttributesConfigCode;

      if (str.indexOf('+') == -1) //not found 
        {
          getAccesories = true;
        }
    }

    if (getAccesories == false) {
      model.ProductAccesoryList = [];
    } else {
      var sendToServerModelJS = CreateSendToServerModelJSForProductAccesories(model);
      AjaxService.GetListOfProductAccessories(sendToServerModelJS).then(function (result) {
        var rez = result.data; //return the list of ProductAccessories

        model.ProductAccesoryList = rez;
        resizeStationAttributeContainer(model);
      });
    }
  }

  function DisplayAddToBasketDialogVI(message) {
    if ($("#dialog-message-vi").length) {
      $("#dialog-message-vi").remove();
    }

    $('body').append($('<div />').attr('id', 'dialog-message-vi').attr('title', window.translation.ValveIslandConfigurator));
    $("#dialog-message-vi").text(message).hide();
    var buttondialog = window.bskdialogbtns;
    DisplayDialog("#dialog-message-vi", {
      buttons: buttondialog
    });
  }

  function DisplayJdeSetupDialogVI(message) {
    if ($("#dialog-message-vi").length) {
      $("#dialog-message-vi").remove();
    }

    $('body').append($('<div />').attr('id', 'dialog-message-vi').attr('title', window.translation.ValveIslandConfigurator));
    $("#dialog-message-vi").text(message).hide();
    DisplayDialog("#dialog-message-vi", {
      buttons: {
        Ok: function Ok() {
          $(this).dialog("close");
        }
      }
    });
  }

  function DisplaySaveConfigurationDialogVI(message) {
    if ($("#dialog-message-vi").length) {
      $("#dialog-message-vi").remove();
    }

    $('body').append($('<div />').attr('id', 'dialog-message-vi').attr('title', window.translation.ValveIslandConfigurator));
    $("#dialog-message-vi").text(message).hide();
    DisplayDialog("#dialog-message-vi", {
      buttons: {
        Ok: function Ok() {
          $(this).dialog("close");
        }
      }
    });
  }

  function DisplayLoginVI(message, url) {
    if ($("#dialog-message-vi").length) {
      $("#dialog-message-vi").remove();
    }

    $('body').append($('<div />').attr('id', 'dialog-message-vi').attr('title', window.translation.ValveIslandConfigurator));
    $("#dialog-message-vi").text(message).hide();
    DisplayDialog("#dialog-message-vi", {
      buttons: {
        Ok: function Ok() {
          $(this).dialog("close");
          window.location.href = url;
        }
      }
    });
  }

  function ShowAjaxLoader() {
    $("#ajax_loader_vi").show();
  }

  function HideAjaxLoader() {
    $("#ajax_loader_vi").hide();
  }

  function GenerateGuid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  function displayDialogBox(message) {
    if ($("#dialog-message-vi").length) {
      $("#dialog-message-vi").remove();
    }

    $('body').append($('<div />').attr('id', 'dialog-message-vi').attr('title', window.translation.ValveIslandConfigurator));
    $("#dialog-message-vi").text(message).hide(); //$.fancybox(message);

    DisplayDialog("#dialog-message-vi", {
      buttons: {
        Ok: function Ok() {
          $(this).dialog("close");
        }
      }
    });
  } // When selecting a pilot supply subsequent stations should be defaulted to the 
  // same value (unless overridden by the user.)


  function setPilotSupplyForSubsequentStations(stationIndexToStart, model, pilotAttributeValue, returnedStation) {
    for (var i = stationIndexToStart; i < model.StationsList.length; i++) {
      var currentStation = model.StationsList[i]; // The pilot supply affects the valve function (vf) option. If a vf is 
      // found we don't want to mess with the user's selection.

      var valveFunctionFound = false;

      for (var j = 0; j < currentStation.SelectedStationAttributesAndValuesList.length; j++) {
        if (currentStation.SelectedStationAttributesAndValuesList[j].attributeID == 6) {
          valveFunctionFound = true;
          break;
        }
      }

      if (valveFunctionFound) continue; // The VM series has pilot supply selected by default, therefore we'll find these
      // values in the station attributes list. VS series valves have no default selection,
      // so we need to add it to the list.

      var pilotSupplyFound = false;

      for (var _j = 0; _j < currentStation.SelectedStationAttributesAndValuesList.length; _j++) {
        if (currentStation.SelectedStationAttributesAndValuesList[_j].attributeID == 5) {
          currentStation.SelectedStationAttributesAndValuesList[_j].attributeValueID = pilotAttributeValue;
          pilotSupplyFound = true;
          break;
        }
      }

      if (!pilotSupplyFound) {
        var newSelectedPair = {
          attributeID: 5,
          attributeValueID: pilotAttributeValue
        };
        currentStation.SelectedStationAttributesAndValuesList.push(newSelectedPair);
      }

      for (j = 0; j < currentStation.StationAttributesList.length; j++) {
        if (currentStation.StationAttributesList[j].AttributeID == 5) {
          // The selected index populates the correct radio button.
          currentStation.StationAttributesList[j].SelectedIndex = returnedStation.StationAttributesList[j].SelectedIndex;

          for (var k = j + 1; k < currentStation.StationAttributesList.length; k++) {
            if (currentStation.StationAttributesList[k].AttributeID == 6) {
              // We need the correct list of valve function values returned from the API call.
              currentStation.StationAttributesList[k].List = returnedStation.StationAttributesList[k].List;
              break;
            }
          }

          break;
        }
      }
    }
  }

  function resizeStationAttributeContainer(model) {
    var maxNumberOfAttributes = 0;

    for (var i = 0; i < model.StationsList.length; i++) {
      var numberOfAttributes = model.StationsList[i].StationAttributesList.length;

      if (numberOfAttributes > maxNumberOfAttributes) {
        maxNumberOfAttributes = numberOfAttributes;
      }
    }

    $("#valve-attribute-height").html(".station-attribute-container { height: " + (maxNumberOfAttributes * 44 + 10) + "px; }");
  }

  $(document).on("click", function (event) {
    if ($(event.target).is(".vi-fancy-select li span")) {
      window.manageFancyDropDown($(event.target).parent());
      return true;
    }

    if ($(event.target).is(".vi-fancy-select li")) {
      window.manageFancyDropDown($(event.target));
      return true;
    }

    if ($(event.target).is(".vi-fancy-select")) {
      window.manageFancyDropDown($(event.target).find("li:visible").first());
      return true;
    } // close any open dropdowns.


    if (!$(event.target).is(".vi-fancy-select li")) {
      $("div.vi-fancy-select.open").each(function () {
        window.closeFancyListBox($(this));
      });
    }
  });
})();